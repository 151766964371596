import map from 'lodash/map';

const withParams = (url, paramsObject = {}) => {
  const keyValuePairs = map(paramsObject, (value, key) => `${key}=${encodeURIComponent(value)}`);
  if (keyValuePairs.length === 0) {
    return url;
  }
  const params = keyValuePairs.join('&');
  return `${url}?${params}`;
};

export default {
  withParams
};
