export default {
  exhibitingOrganisationCreate: {
    loginLink: 'createExhibitingOrganisation_loginLink',
    cancel: 'createExhibitingOrganisation_cancel',
    submit: 'createExhibitingOrganisation_submit',
    companyName: 'createExhibitingOrganisation_companyName',
    displayName: 'createExhibitingOrganisation_displayName',
    exhibitorType: 'createExhibitingOrganisation_exhibitorType',
    package: 'createExhibitingOrganisation_package',
    primaryAdminEmail: 'createExhibitingOrganisation_primaryAdminEmail',
    primaryFirstName: 'createExhibitingOrganisation_primaryAdminFirstName',
    primaryLastName: 'createExhibitingOrganisation_primaryAdminLastName',
    address: {
      addressLine1: 'createExhibitingOrganisation_addressLine1',
      addressLine2: 'createExhibitingOrganisation_addressLine2',
      city: 'createExhibitingOrganisation_city',
      state: 'createExhibitingOrganisation_state',
      postCode: 'createExhibitingOrganisation_postcode',
      country: 'createExhibitingOrganisation_country'
    },
    standReference: 'createExhibitingOrganisation_standReference',
    new_exhibitor: 'createExhibitingOrganisation_features_new',
    features: {
      default: 'createExhibitingOrganisation_features_custom'
    }
  },
  exhibitorHub: {
    loginLink: 'exhibitorHub_loginLink',
    sharers: 'exhibitorHub_sharers',
    emperia: 'exhibitorHub_emperia',
    manageOffer: 'exhibitorHub_manage_offer',
    switchEventEdition: 'exhibitorHub_switchEventEdition',
    profile: 'exhibitorHub_profile',
    digitalUpgrade: 'digital_upgrade',
    digitalUpgradePlaceDone: 'digitalupgrade_done',
    taskList: {
      formDropdown: 'exhibitorHub_taskList_formDropdown',
      taskCompletionCheck: 'exhibitorHub_taskList_taskCompletionCheck'
    },
    myHub: 'exhibitorHub_myHub_headerNav',
    thirdPartyService: {
      Badging: 'exhibitorHub_showUtilities_Badges',
      Leads: 'exhibitorHub_showUtilities_Leads',
      Manual: 'exhibitorHub_showUtilities_Manual',
      Video: 'exhibitorHub_showUtilities_Video',
      Meeting: 'exhibitorHub_showUtilities_Meeting',
      Diary: 'exhibitorHub_showUtilities_Diary'
    },
    followLoginLink: 'exhibitorHub_loginLink',
    load: 'exhibitorHub_component',
    login: 'exhibitorHub_loggedIn',
    visitorInformation: 'visitor_information',
    exhibitorInsights: 'exhibitor_insights',
    exhibitorInsightsProminentTile: 'exhibitor_insights_prominent_tile',
    exhibitorInsightsRegularTile: 'exhibitor_insights_regular_tile',
    exhibitorInsightsStaticTile: 'exhibitor_insights_static_tile',
    exhibitorInsightsLiveTile: 'exhibitor_insights_live_tile',
    exhibitorInsightsOldTile: 'exhibitor_insights_old_tile',
    feedbackButton: 'exhibitorHub_feedbackButton'
  },
  exhibitorDirectory: {
    searchBar: 'exhibitorDirectory_Search',
    searchButton: 'exhibitorDirectory_Search',
    searchResultCount: 'exhibitorDirectory_searchResultCount',
    component: 'exhibitorDirectory_component',
    search: 'exhibitorDirectory_Search',
    filter: 'exhibitorDirectory_Filter',
    filterItem: {
      features: {
        new_exhibitor: 'exhibitorDirectory_feature_new_exhibitor_filterItem',
        innovator: 'exhibitorDirectory_feature_innovator_filterItem',
        default: 'exhibitorDirectory_feature_filterItem',
        exhibitorFilterType: 'exhibitor_featureFilter_component',
        productFilterType: 'product_featureFilter_component'
      },
      products_and_services: {
        default: 'exhibitorDirectory_categoryFilter_productsAndServices_filterItem'
      },
      sectors: {
        default: 'exhibitorDirectory_sector_filterItem'
      },
      venue_name: {
        default: 'exhibitorDirectory_venueName_filterItem'
      },
      default: 'exhibitorDirectory_filterItem'
    },
    productTagEllipsis: 'exhibitorDirectory_productEllipsis',
    filtersOverlay: {
      close: 'exhibitorDirectory_filtersOverlay_close'
    },
    mainStandHolderName: 'exhibitorDirectory_mainStandHolderName',
    inlineRecommendation: 'exhibitorDirectory_inlineRecommendation',
    bronze: {
      exhibitor: 'exhibitorDirectory_exhibitor_bronze',
      exhibitorName: 'exhibitorDirectory_exhibitorName_bronze',
      exhibitorLogo: 'exhibitorDirectory_exhibitorLogo_bronze',
      description: 'exhibitorDirectory_exhibitorDescription_bronze',
      representedBrands: 'exhibitorDirectory_exhibitorBrands_bronze'
    },
    silver: {
      exhibitor: 'exhibitorDirectory_exhibitor_silver',
      exhibitorName: 'exhibitorDirectory_exhibitorName_silver',
      exhibitorLogo: 'exhibitorDirectory_exhibitorLogo_silver',
      websiteLink: 'exhibitorDirectory_websiteLink_silver',
      emailLink: 'exhibitorDirectory_emailLink_silver',
      phoneLink: 'exhibitorDirectory_phoneLink_silver',
      description: 'exhibitorDirectory_exhibitorDescription_silver',
      showObjective: 'exhibitorDirectory_exhibitorShowObjective_silver',
      representedBrands: 'exhibitorDirectory_exhibitorBrands_silver',
      products: 'exhibitorDirectory_exhibitorProducts_silver',
      product: 'exhibitorDirectory_product_silver',
      document: 'exhibitorDirectory_document_silver',
      productImage: 'exhibitorDirectory_exhibitorProductImage_silver',
      productName: 'exhibitorDirectory_exhibitorProductName_silver',
      viewAllProductsLink: 'exhibitorDirectory_exhibitorProducts_viewAllProductsLink_silver',
      prioritySlot: '_prioritySlot',
      viewProductDetailsPage: 'exhibitorDirectory_modal_productLink_silver',
      viewExhibitorDetailsPage: 'exhibitorDirectory_modal_exhibitorLink_silver',
      modalProductCarouselChevron: 'exhibitorDirectory_modal_product_carouselChevron_silver',
      modalDocumentCarouselChevron: 'exhibitorDirectory_modal_document_carouselChevron_silver',
      downloadPdf: 'exhibitorDirectory_modal_downloadPdf_silver'
    },
    gold: {
      exhibitor: 'exhibitorDirectory_exhibitor_gold',
      exhibitorName: 'exhibitorDirectory_exhibitorName_gold',
      mainStandHolderName: 'exhibitorDirectory_mainStandHolderName_gold',
      exhibitorLogo: 'exhibitorDirectory_exhibitorLogo_gold',
      websiteLink: 'exhibitorDirectory_websiteLink_gold',
      emailLink: 'exhibitorDirectory_emailLink_gold',
      phoneLink: 'exhibitorDirectory_phoneLink_gold',
      description: 'exhibitorDirectory_exhibitorDescription_gold',
      showObjective: 'exhibitorDirectory_exhibitorShowObjective_gold',
      representedBrands: 'exhibitorDirectory_exhibitorBrands_gold',
      products: 'exhibitorDirectory_exhibitorProducts_gold',
      product: 'exhibitorDirectory_product_gold',
      document: 'exhibitorDirectory_document_gold',
      productImage: 'exhibitorDirectory_exhibitorProductImage_gold',
      productName: 'exhibitorDirectory_exhibitorProductName_gold',
      viewAllProductsLink: 'exhibitorDirectory_exhibitorProducts_viewAllProductsLink_gold',
      prioritySlot: '_prioritySlot',
      viewProductDetailsPage: 'exhibitorDirectory_modal_productLink_gold',
      viewExhibitorDetailsPage: 'exhibitorDirectory_modal_exhibitorLink_gold',
      modalProductCarouselChevron: 'exhibitorDirectory_modal_product_carouselChevron_gold',
      productCarouselChevron: 'exhibitorDirectory_product_carouselChevron_gold',
      documentCarouselChevron: 'exhibitorDirectory_document_carouselChevron_gold',
      modalDocumentCarouselChevron: 'exhibitorDirectory_modal_document_carouselChevron_gold',
      downloadPdf: 'exhibitorDirectory_modal_downloadPdf_gold'
    },
    exhibitorFeatures: {
      new: 'exhibitorDirectory_exhibitor_features_new'
    },
    favouriteIcon: 'exhibitorDirectory_favourite',
    unFavouriteIcon: 'exhibitorDirectory_unFavourite',
    followIcon: 'exhibitorDirectory_follow',
    unFollowIcon: 'exhibitorDirectory_unFollow',
    gridView: {
      exhibitor: 'exhibitorDirectoryGridView_exhibitor'
    },
    requestQuoteButton: 'exhibitorDirectory_requestQuote',
    sendRequestQuoteButton: 'exhibitorDirectory_sendRequestQuote',
    removeRequestQuoteButton: 'exhibitorDirectory_removeRequestQuote',
    floatingRequestQuoteCheckoutButton: 'exhibitorDirectory_floatingRequestQuoteCheckout',
    floatingRequestQuoteClearAllButton: 'exhibitorDirectory_floatingRequestQuoteClearAll',
    feedbackButton: 'exhibitorDirectory_feedbackButton',
    exhibitorDirectoryRecommendationButton: 'exhibitor_directory_recommendation_button',
    exhibitorDirectoryRecommendationButtonView: 'exhibitor_directory_recommendation_button_view',
    productsTab: 'exhibitorDirectory_productsTab',
    documentsTab: 'exhibitorDirectory_documentsTab',
    prioritySlot: '_prioritySlot',
    exhibitorDirectoryRecommendationCloseButton: 'exhibitor_directory_recommendation_close_button',
    stands: 'exhibitorDirectory_moreStands',
  },
  exhibitorDetails: {
    component: 'exhibitorDetails_component',
    leadCapture: 'exhibitor_leadCaptureForm',
    documents: {
      section: 'exhibitorDetails_documents',
      item: 'exhibitorDetails_documents_item',
      image: 'exhibitorDetails_document_download_image',
      name: 'exhibitorDetails_document_download_name',
      showAllItemsButton: 'exhibitorDetails_documents_showAllDocuments_button',
      showLessItemsButton: 'exhibitorDetails_documents_showLessDocuments_button',
    },
    categories: {
      section: 'exhibitorDetails_categories',
      showAllItemsButton: 'exhibitorDetails_categories_showAllCategories_button',
      showLessItemsButton: 'exhibitorDetails_categories_showLessCategories_button',
    },
    emailLink: 'exhibitorDetails_emailLink',
    phoneLink: 'exhibitorDetails_phoneLink',
    externalLink: 'exhibitorDetails_externalLink',
    accompanyingWebsiteUrl: 'exhibitorDetails_accompanyingWebsiteUrl',
    filters: 'exhibitorDetails_filters',
    products: {
      section: 'exhibitorDetails_products',
      item: 'exhibitorDetails_products_item',
      image: 'exhibitorDetails_product_image',
      name: 'exhibitorDetails_product_name',
      showAllItemsButton: 'exhibitorDetails_products_showAllProducts_button',
      showLessItemsButton: 'exhibitorDetails_products_showLessProducts_button',
      video: 'exhibitorDetails_product_video',
    },
    exhibitorFeatures: {
      new: 'exhibitorDetails_exhibitor_features_new',
      innovator: 'exhibitorDetails_exhibitor_features_innovator'
    },
    favouriteIcon: 'exhibitorDetails_favourite',
    callToAction: 'call_to_action',
    unFavouriteIcon: 'exhibitorDetails_unFavourite',
    followIcon: 'exhibitorDetails_follow',
    unFollowIcon: 'exhibitorDetails_unFollow',
    discoverBanner: 'exhibitorDetails_discoverBanner',
    socialMedia: {
      FACEBOOK: 'exhibitorDetails_follow_facebook',
      TWITTER: 'exhibitorDetails_follow_twitter',
      YOUTUBE: 'exhibitorDetails_follow_youtube',
      LINKEDIN: 'exhibitorDetails_follow_linkedin',
      WECHAT: 'exhibitorDetails_follow_wechat',
      INSTAGRAM: 'exhibitorDetails_follow_instagram'
    },
    session: {
      viewAll: 'exhibitorDetails_viewAllSessions'
    },
    participant: {
      participantDirectory: 'exhibitorDetails_participantDirectory_query',
      participantDetails: 'exhibitorDetails_participantDetails'
    },
    representedProducersTab: 'exhibitorDetails_representedProducersTab',
    exhibitedProducersTab: 'exhibitorDetails_exhibitedProducersTab',
    exhibitorDirectorsTab: 'exhibitorDetails_exhibitorDirectorsTab',
    representedProducer: 'exhibitorDetails_representedProducer',
    exhibitedProducer: 'exhibitorDetails_exhibitedProducer',
    showMoreAddresses: 'exhibitorDetails_showMoreAddresses',
    showLessAddresses: 'exhibitorDetails_showLessAddresses',
    requestQuoteButton: 'exhibitorDetails_requestQuote',
    mainStandHolderName: 'exhibitorDetails_mainStandHolderName',
    stands: 'exhibitorDetail_moreStands',
  },
  exhibitorList: {
    searchResultCount: 'exhibitorList_searchResultCount',
    loginLink: 'exhibitorList_loginLink',
    exhibitor: 'exhibitorList_exhibitor',
    exhibitorName: 'exhibitorList_exhibitorName',
    exhibitorLogo: 'exhibitorList_exhibitorLogo',
    exhibitorDashboardLink: 'exhibitorList_exhibitorDashboardLink',
    downloadPressReleases: 'exhibitorList_downloadPressReleases',
    downloadExhibitorList: 'exhibitorList_downloadExhibitorList',
    downloadExhibitorEngagementReport: 'exhibitorList_engagement_report_download',
    downloadProductReport: 'exhibitorList_product_report_download',
    viewAllExhibitorLeads: 'exhibitorList_viewAllExhibitorLeads',
    search: {
      input: 'exhibitorList_searchInput',
      submit: 'exhibitorList_searchSubmit'
    }
  },
  exhibitorProfile: {
    companyAdmin: {
      edit: 'exhibitorProfile_companyAdmin_edit',
      save: 'exhibitorProfile_companyAdmin_save',
      cancel: 'exhibitorProfile_companyAdmin_cancel',
      loginLink: 'exhibitorProfile_companyAdmin_loginLink',
      resendWelcomeEmail: 'exhibitorProfile_companyAdmin_resendWelcomeEmail',
      email: 'exhibitorProfile_companyAdmin_email',
      delete: 'exhibitorProfile_companyAdmin_delete'
    },
    navigation: {
      profile: 'exhibitorProfile_tabToProfile',
      sharers: 'exhibitorProfile_tabToSharers'
    },
    logo: {
      loginLink: 'exhibitorProfile_exhibitorLogo_loginLink',
      placeholder: 'exhibitorProfile_exhibitorLogo',
      edit: 'exhibitorProfile_exhibitorLogo_edit',
      delete: 'exhibitorProfile_exhibitorLogo_delete'
    },
    coverImage: {
      loginLink: 'exhibitorProfile_exhibitorCoverImage_loginLink',
      placeholder: 'exhibitorProfile_exhibitorCoverImage',
      edit: 'exhibitorProfile_exhibitorCoverImage_edit',
      delete: 'exhibitorProfile_exhibitorCoverImage_delete'
    },
    information: {
      loginLink: 'exhibitorProfile_information_loginLink',
      edit: 'exhibitorProfile_information_edit',
      save: 'exhibitorProfile_information_save',
      cancel: 'exhibitorProfile_information_cancel',
      showObjective: 'exhibitorProfile_information_showObjective',
      showObjectiveEdit: 'exhibitorProfile_information_clickableShowObjective',
      description: 'exhibitorProfile_information_description',
      descriptionEdit: 'exhibitorProfile_information_clickableDescription',
      descriptionPopulated: 'exhibitorProfile_information_PopulatedDescription',
      displayName: 'exhibitorProfile_information_displayName',
      standReference: 'exhibitorProfile_information_standReference'
    },
    standReference: {
      loginLink: 'exhibitorProfile_stand_loginLink',
      edit: 'exhibitorProfile_stand_edit',
      save: 'exhibitorProfile_stand_save',
      cancel: 'exhibitorProfile_stand_cancel',
      standReference: 'exhibitorProfile_stand_standReference'
    },
    representedBrands: {
      loginLink: 'exhibitorProfile_representedBrands_loginLink',
      edit: 'exhibitorProfile_representedBrands_edit',
      save: 'exhibitorProfile_representedBrands_save',
      cancel: 'exhibitorProfile_representedBrands_cancel',
      representedBrands: 'exhibitorProfile_representedBrands_representedBrands',
      representedBrandsEdit: 'exhibitorProfile_representedBrands_clickableRepresentedBrands'
    },
    filters: {
      loginLink: 'exhibitorProfile_filters_loginLink',
      edit: 'exhibitorProfile_filters_edit',
      save: 'exhibitorProfile_filters_save',
      cancel: 'exhibitorProfile_filters_cancel',
      filters: 'exhibitorProfile_filters_filters',
      filtersEdit: 'exhibitorProfile_filters_clickableFilters'
    },
    filterCategories: {
      loginLink: 'exhibitorProfile_filterCategories_loginLink',
      edit: 'exhibitorProfile_filterCategories_edit',
      save: 'exhibitorProfile_filterCategories_save',
      cancel: 'exhibitorProfile_filterCategories_cancel',
      filterCategories: 'exhibitorProfile_filterCategories_filterCategories',
      filterCategoriesEdit: 'exhibitorProfile_filterCategories_clickablefilterCategories'
    },
    sponsoredCategory: {
      loginLink: 'exhibitorProfile_sponsoredCategory_loginLink',
      edit: 'exhibitorProfile_sponsoredCategory_edit',
      save: 'exhibitorProfile_sponsoredCategory_save',
      cancel: 'exhibitorProfile_sponsoredCategory_cancel',
      sponsoredCategory: 'exhibitorProfile_sponsoredCategory_sponsoredCategory',
      sponsoredCategoryEdit: 'exhibitorProfile_sponsoredCategory_clickablesponsoredCategory'
    },
    matchmaking: {
      loginLink: 'exhibitorProfile_matchmaking_loginLink',
      edit: 'exhibitorProfile_matchmaking_edit',
      save: 'exhibitorProfile_matchmaking_save',
      cancel: 'exhibitorProfile_matchmaking_cancel',
      matchmaking: 'exhibitorProfile_matchmaking_matchmaking',
      matchmakingEdit: 'exhibitorProfile_matchmaking_clickableMatchmaking'
    },
    products: {
      prefix: 'exhibitorProfile_products',
      loginLink: 'exhibitorProfile_products_loginLink',
      add: 'exhibitorProfile_products_add',
      edit: 'exhibitorProfile_products_edit',
      save: 'exhibitorProfile_products_save',
      cancel: 'exhibitorProfile_products_cancel',
      placeholder: 'exhibitorProfile_products_imagePlaceholder',
      delete: 'exhibitorProfile_products_delete',
      changeImage: 'exhibitorProfile_products_changeImage',
      toggleInnovative: 'exhibitorProfile_products_toggleInnovative',
      toggleIsNew: 'exhibitorProfile_products_toggleIsNew',
      exhibitorClicksIsInnovative: 'exhibitorProfile_products_toggleInnovative_exhibitor',
      exhibitorClicksIsNew: 'exhibitorProfile_products_toggleNew_exhibitor',
      productsUploadNoEntitlement: 'productUploads_purchase_noEntitlement',
      productsUploadMoreProducts: 'productUploads_purchase_moreProducts',
      productsUploadHitLimitMoreProducts: 'productUploads_purchase_hitLimit_moreProducts',
      features: {
        default: 'products_features_custom'
      }
    },
    contactUs: {
      accompanyingWebsiteUrl: 'exhibitorProfile_externalLinks_accompanyingWebsiteUrl',
      accompanyingWebsiteUrlEdit: 'exhibitorProfile_externalLinks_clickableAccompanyingWebsiteUrl',
      loginLink: 'exhibitorProfile_contactUs_loginLink',
      edit: 'exhibitorProfile_contactUs_edit',
      save: 'exhibitorProfile_contactUs_save',
      cancel: 'exhibitorProfile_contactUs_cancel',
      addressLine1: 'exhibitorProfile_contactUs_addressLine1',
      addressLine2: 'exhibitorProfile_contactUs_addressLine2',
      city: 'exhibitorProfile_contactUs_city',
      state: 'exhibitorProfile_contactUs_state',
      postCode: 'exhibitorProfile_contactUs_postcode',
      country: 'exhibitorProfile_contactUs_country',
      phone: 'exhibitorProfile_contactUs_phone',
      phoneEdit: 'exhibitorProfile_contactUs_clickablePhone',
      email: 'exhibitorProfile_contactUs_email',
      emailEdit: 'exhibitorProfile_contactUs_clickableEmail',
      emailLink: 'exhibitorProfile_followEmailLink',
      websiteUrl: 'exhibitorProfile_externalLinks_websiteUrl',
      websiteUrlEdit: 'exhibitorProfile_externalLinks_clickableWebsiteUrl',
      externalLink: 'exhibitorProfile_followExternalLink'
    },
    externalLinks: {
      loginLink: 'exhibitorProfile_externalLinks_loginLink',
      edit: 'exhibitorProfile_externalLinks_edit',
      save: 'exhibitorProfile_externalLinks_save',
      cancel: 'exhibitorProfile_externalLinks_cancel',
      websiteUrl: 'exhibitorProfile_externalLinks_websiteUrl',
      websiteUrlEdit: 'exhibitorProfile_externalLinks_clickableWebsiteUrl',
      externalLink: 'exhibitorProfile_followExternalLink'
    },
    socialMedia: {
      loginLink: 'exhibitorProfile_socialMedia_loginLink',
      edit: 'exhibitorProfile_socialMedia_edit',
      save: 'exhibitorProfile_socialMedia_save',
      cancel: 'exhibitorProfile_socialMedia_cancel',
      socialMediaUrlEdit: 'exhibitorProfile_socialMedia_clickableWebsiteUrl',
      socialMediaUrl: 'exhibitorProfile_socialMedia_socialMediaUrl',
      socialMediaEdit: {
        FACEBOOK: 'exhibitorProfile_socialMedia_facebookUrl',
        TWITTER: 'exhibitorProfile_socialMedia_twitterUrl',
        YOUTUBE: 'exhibitorProfile_socialMedia_youtubeUrl',
        LINKEDIN: 'exhibitorProfile_socialMedia_linkedinUrl',
        WECHAT: 'exhibitorProfile_socialMedia_wechatUrl',
        INSTAGRAM: 'exhibitorProfile_socialMedia_instagramUrl'
      },
      socialMediaView: 'exhibitorProfile_follow_facebook',
      multipleSocialMedia: {
        FACEBOOK: 'exhibitorProfile_follow_facebook',
        TWITTER: 'exhibitorProfile_follow_twitter',
        YOUTUBE: 'exhibitorProfile_follow_youtube',
        LINKEDIN: 'exhibitorProfile_follow_linkedin',
        WECHAT: 'exhibitorProfile_follow_wechat',
        INSTAGRAM: 'exhibitorProfile_follow_instagram'
      }
    },
    documents: {
      loginLink: 'exhibitorProfile_documents_loginLink',
      upload: 'exhibitorProfile_documents_upload',
      delete: 'exhibitorProfile_documents_delete',
      download: 'exhibitorProfile_documents_download'
    },
    settings: {
      loginLink: 'exhibitorProfile_settings_loginLink',
      edit: 'exhibitorProfile_settings_edit',
      save: 'exhibitorProfile_settings_save',
      cancel: 'exhibitorProfile_settings_cancel',
      exhibitorStatus: 'exhibitorProfile_settings_exhibitorStatus',
      package: 'exhibitorProfile_settings_package',
      sortAlias: 'exhibitorProfile_settings_sortAlias',
      companyName: 'exhibitorProfile_settings_companyName',
      companyNameAide: 'exhibitorProfile_settings_companyNameAide',
      new_exhibitor: 'exhibitorProfile_settings_features_new'
    },
    features: {
      new_exhibitor: 'exhibitorProfile_settings_features_new',
      default: 'exhibitorProfile_settings_features_extra_characteristics'
    },
    profileHeader: {
      profileSelect: 'exhibitorProfile_profileHeader_switchEventEdition',
      languageSelect: 'exhibitorProfile_profileHeader_switchLanguage'
    },
    exhibitorEvents: {
      loginLink: 'exhibitorProfile_events_loginLink',
      save: 'exhibitorProfile_events_save',
      add: 'exhibitorProfile_events_add',
      delete: 'exhibitorProfile_events_delete',
      edit: 'exhibitorProfile_events_edit',
      cancel: 'exhibitorProfile_events_cancel',
      viewAll: 'exhibitorProfile_viewAllSessions'
    },
    showUtilities: {
      Badging: 'showUtilities_Badging',
      Leads: 'showUtilities_Leads',
      Manual: 'showUtilities_Manual',
      Video: 'showUtilities_Video',
      Meeting: 'showUtilities_Meeting',
      Diary: 'showUtilities_Diary'
    },
    followLoginLink: 'exhibitorProfile_loginLink'
  },
  manageSharer: {
    add: 'exhibitorProfile_manageSharers_add',
    standSharerList: {
      standSharer: 'exhibitorProfile_manageSharers_sharerLink'
    },
    standSharers: {
      standSharer: 'exhibitorProfile_manageSharers_sharerLink'
    },
    standSharerCreate: {
      loginLink: 'exhibitorProfile_standSharerCreate_loginLink',
      cancel: 'exhibitorProfile_standSharerCreate_cancel',
      submit: 'exhibitorProfile_standSharerCreate_submit',
      companyName: 'exhibitorProfile_standSharerCreate_companyName',
      displayName: 'exhibitorProfile_standSharerCreate_displayName',
      stand: 'exhibitorProfile_standSharerCreate_stand',
      primaryAdminEmail: 'exhibitorProfile_standSharerCreate_primaryAdminEmail',
      primaryAdminFirstName: 'exhibitorProfile_standSharerCreate_primaryAdminFirstName',
      primaryAdminLastName: 'exhibitorProfile_standSharerCreate_primaryAdminLastName',
      new_exhibitor: 'exhibitorProfile_standSharerCreate_features_new',
      features: {
        default: 'exhibitorProfile_standSharerCreate_features_custom'
      },
      address: {
        addressLine1: 'exhibitorProfile_standSharerCreate_addressLine1',
        addressLine2: 'exhibitorProfile_standSharerCreate_addressLine2',
        city: 'exhibitorProfile_standSharerCreate_city',
        state: 'exhibitorProfile_standSharerCreate_state',
        postCode: 'exhibitorProfile_standSharerCreate_postcode',
        country: 'exhibitorProfile_standSharerCreate_country'
      }
    }
  },
  createFilter: {
    filterName: 'createFilter_filterName',
    filterItems: 'createFilter_filterItems',
    loginLink: 'createFilter_loginLink',
    publishButton: 'createFilter_publishButton'
  },
  viewFilter: {
    editButton: 'viewFilter_editButton'
  },
  editFilter: {
    addFilterItemButton: 'editFilter_addFilterItemButton',
    addProductFilterItemButton: 'editFilter_addProductFilterItemButton',
    publishButton: 'editFilter_publishButton_{0}',
    cancelButton: 'editFilter_cancelButton',
    deleteFilterGroupButton: 'editFilter_deleteFilterGroupButton',
    deleteItemButton: 'editFilter_filterItems_deleteButton',
    newFilterItemInput: 'editFilter_filterItems_newFilterItemInput',
    loginLink: 'editFilter_loginLink',
    editFilterGroupName: 'editFilter_groupName'
  },
  jobTitle: {
    deleteItemButton: 'manageJobTitle_deleteJobTitle',
    addItemButton: 'manageJobTitle_addJobTitle',
    publishButton: 'manageJobTitle_publishButton_{0}',
    newFilterItemInput: 'manageJobTitle_filterItems_newFilterItemInput',
    loginLink: 'manageJobTitle_loginLink'
  },
  eventEditionList: {
    businessUnitSelect: 'eventEditionList_selectBusinessUnit',
    consolidatedReport: 'eventEditionList_consolidatedReport',
    showSettings: {
      edit: 'eventEditionList_showSettings_edit',
      save: 'eventEditionList_showSettings_save',
      cancel: 'eventEditionList_showSettings_cancel',
      loginLink: 'eventEditionList_showSettings_edit_loginLink'
    }
  },
  producerDirectory: {
    component: 'producerDirectory_component',
    producer: 'producerDirectory_producer',
    searchResultCount: 'producerDirectory_searchResultCount',
    searchBar: 'producerDirectory_searchBar',
    searchButton: 'producerDirectory_searchButton'
  },
  artistList: {
    searchResultCount: 'artistList_searchResultCount',
    loginLink: 'artistList_loginLink',
    artist: 'artistList_artist',
    artistName: 'artistList_artistName',
    artistLogo: 'artistList_artistLogo',
    downloadPressReleases: 'artistList_downloadPressReleases',
    downloadExhibitorList: 'artistList_downloadExhibitorList',
    viewAllExhibitorLeads: 'artistList_viewAllExhibitorLeads',
    search: {
      input: 'artistList_searchInput',
      submit: 'artistList_searchSubmit'
    }
  },
  productDirectory: {
    component: 'productDirectory_component',
    product: 'productDirectory_product',
    search: 'productDirectory_Search',
    searchResultCount: 'productDirectory_searchResultCount',
    productImage: 'productDirectory_productImage',
    vidyardVideo: 'productDirectory_productVideo',
    productName: 'productDirectory_productName',
    exhibitorName: 'productDirectory_exhibitorName',
    products: {
      section: 'productDirectory_products',
      image: 'productDirectory_product_image',
      back: 'productDirectory_product_back'
    },
    discoverBanner: {
      productCount: 'productDirectory_discoverProductCount',
      product: 'productDirectory_discoverProduct',
      certifications: 'productDirectory_discoverCertifications'
    },
    searchBar: 'productDirectory_Search',
    searchButton: 'productDirectory_Search',
    filter: 'productDirectory_Filter',
    filterItem: {
      features: {
        innovative: 'productDirectory_feature_innovative_filterItem',
        new_product: 'productDirectory_feature_new_product_filterItem',
        default: 'productDirectory_Filter'
      }
    },
    favouriteIcon: 'productDirectory_favourite',
    unFavouriteIcon: 'productDirectory_unFavourite',
    sortOptions: {
      productNameAsc: 'productdirectory_ascendingsortbyProductname',
      lastUpdatedAtDesc: 'productdirectory_sortbyRecentlyedited'
    },
    video: 'productDirectory_clickVideo',
    prioritySlot: '_prioritySlot'
  },
  productDetails: {
    section: 'productDetails_section',
    back: 'productDetails_back',
    innovative: 'productDetails_innovative',
    productCarousel: {
      productItem: 'productDetails_productItem',
      productImage: 'productDetails_productImage',
      productName: 'productDetails_productName',
      navigation: 'productDetails_products_navarrows'
    },
    exhibitorLink: 'productDetails_exhibitorLink',
    favouriteIcon: 'productDetails_favourite',
    unFavouriteIcon: 'productDetails_unFavourite',
    video: 'productDetails_clickVideo',
    productDetails_callToAction: 'productDetails_call_to_action',
    requestQuoteButton: 'productDetails_requestQuote',
    discoverBanner: 'productDetails_discoverBanner',
    genericDiscoverBanner: 'common_genericDiscoverBanner',
    documents: {
      download: 'productDetails_documents_download'
    },
  },
  manageThirdParty: {
    singleSignOn: {
      edit: 'manageThirdParty_thirdParty_edit',
      save: 'manageThirdParty_thirdParty_save',
      cancel: 'manageThirdParty_thirdParty_cancel',
      loginLink: 'manageThirdParty_thirdParty_loginLink'
    },
    followLoginLink: 'manageThirdParty_loginLink',
    video: 'productDirectory_clickVideo'
  },
  producerDetails: {
    section: 'producerDetails_section',
    producerImage: 'producerDetails_producerImage',
    producerExhibitingCompany: 'producerDetails_exhibitingCompany',
    producerRepresentingCompany: 'producerDetails_representingCompany',
    productCarousel: {
      productItem: 'producerDetails_productItem',
      productImage: 'producerDetails_productImage',
      productName: 'producerDetails_productName',
      navigation: 'producerDetails_products_navarrows'
    }
  },
  discover: {
    genericDiscoverBanner: 'common_genericDiscoverBanner'
  },
  contactForm: {
    feedbackButton: 'contactForm_feedbackButton'
  },
  generic: {
    exhibitorsGoldCarouselDirectoryButton: 'exhibitors-gold-carousel-directory-button',
    exhibitorsGoldCarouselDetailsLink: 'exhibitors-gold-carousel-details-link',
    exhibitorsGoldCarouselItem: 'exhibitorGoldCarousel_Item'
  }
};
