import TranslationService from '../../translations';
import Logger from '../../logger';
import Utils from '../../common/Utils';
import isEmpty from 'lodash/isEmpty'

const clientError = (errorTranslations, isSaving, error) => ({
  debug: error,
  errors: [isSaving ? errorTranslations.client_error_saving : errorTranslations.client_error],
  fieldErrors: {}
});

const isUnauthorized = res => res.body && res.status === 401 && res.body.errors[0].code === 'unauthorized';

const isGraphqlUnauthorized = data => data.errors && data.errors.length > 0 && data.errors[0].extensions.code === 'unauthorized';

const errorLogger = {
  isServerError: res => res.body && res.status >= 500,
  isClientError: res => !res.body,
  isGraphQLError: res => res.body.errors && res.body.errors.length > 0,
  log: (result) => {
    if (errorLogger.isServerError(result)) {
      Logger.logWarn(JSON.stringify(result));
    } else if (errorLogger.isClientError(result)) {
      Logger.logWarn(JSON.stringify(result, Object.getOwnPropertyNames(result)));
    } else if (errorLogger.isGraphQLError(result)) {
      Logger.logWarnWithEvent('GraphQL request failed with errors', result);
    }
  }
};

const errorMapper = {
  mapErrors: (errorTranslations, errors, isSaving, prefix) =>
    errors.map((error) => {
      let translationKey = isSaving ? `${error.code}_saving` : error.code;
      translationKey = prefix ? `${prefix}_${translationKey}` : translationKey;
      return errorTranslations[translationKey] || `[${error.code}] ${error.message || ''}`;
    }),

  errorMessageKey: (code, fieldName) => {
    const arraySelector = /(\W\d+\W)?/g;
    const allDots = /\./g;
    const formattedName = fieldName.replace(arraySelector, '').replace(allDots, '_');
    return `${code}_${formattedName}`;
  },

  mapFieldError: (errorTranslations, fieldError, prefix) => {
    const errorMessages = fieldError.errors.map((error) => {
      const prefixedFieldName = prefix ? `${prefix}_${fieldError.name}` : fieldError.name;
      const messageKey = errorMapper.errorMessageKey(error.code, prefixedFieldName);
      return errorTranslations[messageKey];
    });

    return { [fieldError.name]: errorMessages };
  },

  getFieldErrors: (fieldValidationErrors) => {
    const fieldErrorsFromResponse = [];
    if (fieldValidationErrors) {
      fieldValidationErrors.forEach(x =>
        x.errors.forEach((y) => {
          fieldErrorsFromResponse.push(Object.prototype.hasOwnProperty.call(y, 'propertyName')
            ? { name: y.propertyName, errors: [y] } : { name: x.name, errors: x.errors });
        }));
    }
    return fieldErrorsFromResponse;
  },

  mapFieldErrors: (errorTranslations, fieldErrors, prefix) =>
    fieldErrors
      .map(fieldError => errorMapper.mapFieldError(errorTranslations, fieldError, prefix))
      .reduce((accumulator, fieldError) => Object.assign({}, accumulator, fieldError), {}),

  mapResponse: (errorTranslations, result, isSaving, fieldErrorPrefix, errorPrefix) => ({
    debug: result,
    errors: errorMapper.mapErrors(errorTranslations, result.errors, isSaving, errorPrefix),
    fieldErrors: errorMapper.mapFieldErrors(errorTranslations, errorMapper.getFieldErrors(result.fieldValidationErrors), fieldErrorPrefix)
  }),

  mapValidatorErrorForField: (errorTranslations, validatorErrorsForField, fieldName) => {
    const translatedErrors = validatorErrorsForField.map(errorCode => errorTranslations[errorMapper.errorMessageKey(errorCode, fieldName)]);
    return { [fieldName]: translatedErrors };
  },

  mapValidatorErrors: (errorTranslations, validatorErrors) => {
    const fields = Object.keys(validatorErrors);
    return fields
      .map(fieldName => errorMapper.mapValidatorErrorForField(errorTranslations, validatorErrors[fieldName], fieldName))
      .reduce((accumulator, validatorError) => Object.assign({}, accumulator, validatorError), {});
  }
};

const ServiceResultFactory = {
  fromSuccess(data) {
    return {
      ...data,
      mustLogIn: false,
      errors: [],
      fieldErrors: {}
    };
  },
  fromGraphqlSuccess(response, locale, key) {
    const responseBody = response.body;

    if (isGraphqlUnauthorized(responseBody)) {
      return { mustLogIn: true, errors: [], fieldErrors: {} };
    }

    if (responseBody.errors && responseBody.errors.length > 0) {
      const codeToBeIgnoredFromFieldErrors = 'exhibiting_organisation_already_exists';
      const errorTranslations = TranslationService.getTranslations(locale).errorMessages;
      errorLogger.log(response);
      return {
        ...responseBody.data[key],
        errorResponse: responseBody.errors,
        fieldErrors: Utils.constructErrorFieldMessageMapFromGraphQlResponse(responseBody, errorTranslations, codeToBeIgnoredFromFieldErrors),
        mustLogIn: false,
        errors: Utils.captureResponseCodeAsError(responseBody, errorTranslations, codeToBeIgnoredFromFieldErrors)
      };
    }

    if (Array.isArray(responseBody.data[key])) {
      return {
        ...responseBody.data,
        mustLogIn: false,
        errors: [],
        fieldErrors: {}
      };
    }

    return {
      ...responseBody.data[key],
      mustLogIn: false,
      errors: [],
      fieldErrors: {}
    };
  },
  fromGraphql(response, locale, key) {
    const responseBody = response.body;

    if (isGraphqlUnauthorized(responseBody)) {
      return { mustLogIn: true, errors: [], fieldErrors: {} };
    }

    if (responseBody.errors && responseBody.errors.length > 0) {
      const errorTranslations = TranslationService.getTranslations(locale).errorMessages;
      const errors = responseBody.errors.map((error) => {
        let translationKey = error.extensions.code;
        return errorTranslations[translationKey] || `[${error.extensions.code}] ${error.message || ''}`;
      });
      errorLogger.log(response);
      return {
        ...responseBody.data[key],
        errorResponse: responseBody.errors,
        errors: errors,
        fieldErrors: Utils.constructErrorFieldMessageMapFromGraphQlResponse(responseBody, errorTranslations),
        mustLogIn: false,
      }
    }
    const data = !isEmpty(key)
       ? responseBody.data[key]
       : responseBody.data;

    return {
      ...data,
      mustLogIn: false,
      errors: [],
      fieldErrors: {}
    };
  },
  fromError(locale, result, isSaving, fieldErrorPrefix, errorPrefix) {
    const errorTranslations = TranslationService.getTranslations(locale).errorMessages;

    if (isUnauthorized(result)) {
      return { mustLogIn: true, errors: [], fieldErrors: {} };
    }

    errorLogger.log(result);

    return result.body ? errorMapper.mapResponse(errorTranslations, result.body, isSaving, fieldErrorPrefix, errorPrefix) :
      clientError(errorTranslations, isSaving, result);
  },
  fromErrorCode(locale, errorCode) {
    const errorTranslations = TranslationService.getTranslations(locale).errorMessages;
    return {
      errors: [errorTranslations[errorCode]],
      fieldErrors: {}
    };
  },
  fromValidator(locale, validatorErrors) {
    const errorTranslations = TranslationService.getTranslations(locale).errorMessages;
    return {
      errors: [],
      fieldErrors: errorMapper.mapValidatorErrors(errorTranslations, validatorErrors)
    };
  }
};

export default ServiceResultFactory;
