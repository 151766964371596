export default {
  getTranslations(locale) {
    const settings =
      global.window.reactSettings ||
      global.window.reactSettingsExhibitorscarousel ||
      global.window.reactSettingsExhibitorHub ||
      global.window.reactSettingsTasklist ||
      global.window.reactSettingsProductsDirectory ||
      global.window.reactSettingsProductDetails ||
      global.window.reactSettingsArtistsDirectory ||
      global.window.reactSettingsArtistDetails ||
      global.window.reactSettingsManageAdminRoles;
    return settings.props.localisation[locale] || settings.props.localisation['en-GB']; // Default to english
  }
};
