import React from 'react';
import reactStringReplace from 'react-string-replace';

const FORMAT_STRING_VARIABLE_REGEX = /\{(\d+)}/g;

const formatToArray = (formatString, replacements) =>
  reactStringReplace(formatString, FORMAT_STRING_VARIABLE_REGEX, match => replacements[match]);

const format = (formatString, ...replacements) => formatString
  .replace(FORMAT_STRING_VARIABLE_REGEX, (match) => {
    const number = match.slice(1, -1);
    return replacements[number];
  });

const strongWithKey = (inner, index) => <strong key={`${index}_${inner}`}>{inner}</strong>;
const iconWithKey = (inner, index) => <i key={`${index}_${inner}`} className={`${inner}`}></i>;

const StringFormatter = {
  strong(message, ...replacements) {
    const strongReplacements = replacements.map(strongWithKey);
    return formatToArray(message, strongReplacements);
  },

  icon(message, ...replacements) {
    const iconReplacements = replacements.map(iconWithKey);
    return formatToArray(message, iconReplacements);
  },

  strongWithBreak(message, ...replacements) {
    const strongReplacements = replacements
      .map((replacement, index) => [<br />, strongWithKey(replacement, index)]);
    return formatToArray(message, strongReplacements);
  },

  truncateText(text, maxLength) {
    if (!text) {
      return '';
    }
    if (text.length <= maxLength) {
      return text;
    }

    return `${text.substr(0, maxLength)}…`;
  },

  format,
  formatToArray
};

export default StringFormatter;
