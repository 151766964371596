import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/lib/Alert';
import Classes from '../Classes';

const ErrorContainer = ({ errors }) => {
let counter=0;
return <div>
    {
    errors.map(error => {
      counter++;
    return <Alert key={error +'-'+ counter} bsStyle={Classes.danger}>{error}</Alert>
    })
  }
  </div>
};

ErrorContainer.propTypes = {
  errors: PropTypes.array.isRequired
};

export default ErrorContainer;
