import React from 'react';
import { render } from 'react-dom';
import { HashRouter } from 'react-router-dom';
import ExhibitorsCarousel from '../../src/js/exhibitorsCarousel/ExhibitorsCarousel';
import { authController } from '@reedexpo/authorization-component-ui';

export default ExhibitorsCarousel;

if (global.window && global.window.document) {
  const { props, elementId, idpUrl } = window.reactSettingsExhibitorscarousel;
  const propsForChildren = Object.assign({}, props, { idpUrl: props.idpUrl || idpUrl });
  const authSettings = window.authSettings;
  if (authSettings && authSettings.usePkce) {
    authController.initialize(authSettings);
  }
  render(<HashRouter><ExhibitorsCarousel {... propsForChildren} /></HashRouter>, document.getElementById(elementId));
}