/* global window:true */
import uuid from 'uuid';
import Uri from './uri';
import { fetch } from './fetch';
import authorizationStore from './authorizationStore';
import Logger from '../../logger';

const httpVerb = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE'
};

const status = {
  OK: 200,
  BAD_REQUEST: 400
};

const acceptType = 'application/json';
const contentType = 'application/json';

const mergeHeaders = async (customHeaders, skipToken) => {
  const token = skipToken ? null : await authorizationStore.getToken();
  const clientId = authorizationStore.getClientId();
  const bearer = (token && !skipToken) ? { Authorization: `Bearer ${token}` } : {};
  const xClientId = clientId ? { 'X-ClientId': clientId } : {};
  const xCorrelationId = { 'X-CorrelationId': uuid.v4() };
  return Object.assign({ Accept: acceptType }, bearer, xClientId, xCorrelationId, customHeaders);
};

const performFetch = async (url, method, body, customHeaders = {}) => {
  const headers = await mergeHeaders(customHeaders);
  Logger.logInfo(`Fetching ${url} from ${global.window.location.origin}`, null, headers['X-CorrelationId']);
  return fetch(url, { method, headers, body });
};

const shouldReject = response => response.status >= status.BAD_REQUEST;

const extractStatusAndBody = resp => resp
  .json()
  .then(body => ({ status: resp.status, body }));

const extractFileData = resp => resp
  .blob()
  .then((data) => {
    const name = resp.headers.get('Content-Disposition').split('attachment; filename=')[1];
    const type = resp.headers.get('Content-Type');
    return { status: resp.status, file: { name, data, type } };
  });

const processFileReponse = (resp) => {
  if (shouldReject(resp)) {
    return extractStatusAndBody(resp);
  }
  return extractFileData(resp);
};

const resultForStatus = (result) => {
  if (shouldReject(result)) {
    return Promise.reject(result);
  }
  return result;
};

const resultWithNoSuccessBody = (response) => {
  if (shouldReject(response)) {
    return extractStatusAndBody(response)
      .then(resultForStatus);
  }
  if (response.headers && response.headers.get('Location')) {
    return { status: response.status, location: response.headers.get('Location') };
  }
  return { status: response.status };
};

const getRequest = (url, params) => {
  const urlWithParams = Uri.withParams(url, params);
  return performFetch(urlWithParams, httpVerb.GET)
    .then(extractStatusAndBody)
    .then(resultForStatus);
};


const getFileRequest = (url, params) => {
  const urlWithParams = Uri.withParams(url, params);
  return performFetch(urlWithParams, httpVerb.GET)
    .then(processFileReponse)
    .then(resultForStatus);
};

const requestWithBody = (verb, url, body, params = {}, headers = {}) => {
  const urlWithParams = Uri.withParams(url, params);

  return performFetch(urlWithParams, verb, body, headers)
    .then(resultWithNoSuccessBody);
};

const deleteRequest = (url, params) => {
  const urlWithParams = Uri.withParams(url, params);
  return performFetch(urlWithParams, httpVerb.DELETE)
    .then(resultWithNoSuccessBody);
};

const createFileBody = (file) => {
  const formData = new window.FormData();
  formData.append('file', file);
  return formData;
};

const createBody = (body) => {
  const formData = new window.FormData();
  Object.keys(body).forEach((key) => {
    formData.append(`${key}`, `${body[key]}`);
  });
  return formData;
};
const postWithFormData = verb => (url, body, params) => requestWithBody(verb, url, createBody(body), params);

const requestWithFile = verb => (url, body, params) =>
  requestWithBody(verb, url, createFileBody(body), params);

const requestWithJson = verb => (url, body, params) => {
  const customHeaders = { 'Content-Type': contentType };
  return requestWithBody(
    verb, url, JSON.stringify(body),
    params, customHeaders
  );
};

const publicPostWithJson = async (url, body, customHeaders) => {
  const contentTypeHeader = { 'Content-Type': contentType };
  const headers = {...customHeaders, ...contentTypeHeader};
  return makeRequest(url, await mergeHeaders(headers, true), JSON.stringify(body));
};

const makeRequest = (url, headers, body) => fetch(url, { method: httpVerb.POST, headers, body })
  .then(extractStatusAndBody)
  .then(resultForStatus);

const prepareBody = (query, variables) => JSON.stringify({
  query,
  variables
});

const graphqlRequest = async (url, query, variables) =>
  makeRequest(url, await mergeHeaders({ 'Content-Type': contentType }, false), prepareBody(query, variables));

const magentoGraphqlRequest = async (url, query, variables, store) =>
  makeRequest(url, await mergeHeaders({ 'Content-Type': contentType, store }, false), prepareBody(query, variables));

const publicGraphqlRequest = async (url, query, variables) =>
  makeRequest(url, await mergeHeaders({ 'Content-Type': contentType }, true), prepareBody(query, variables));


export default {
  get: getRequest,
  getFile: getFileRequest,
  put: requestWithJson(httpVerb.PUT),
  putFile: requestWithFile(httpVerb.PUT),
  post: requestWithJson(httpVerb.POST),
  publicPost: publicPostWithJson,
  postFile: requestWithFile(httpVerb.POST),
  postWithFormData: postWithFormData(httpVerb.POST),
  delete: deleteRequest,
  graphqlRequest,
  publicGraphqlRequest,
  magentoGraphqlRequest
};
